/* eslint-disable indent */
/* eslint-disable comma-dangle */
/* eslint-disable no-trailing-spaces */

type StaticImages = {
    readonly [fileName: string]: {
        uri?: string;
    };
}

const imageStaticSourcesByFileName: StaticImages = {
    'bW37zRULAjG2tQp7gK1JEF.png': { uri: '/images/bW37zRULAjG2tQp7gK1JEF.png' },
's9aLxs5X9myKhibSsFsnYW.png': { uri: '/images/s9aLxs5X9myKhibSsFsnYW.png' },
'2qohUS6C5LN8aNdtit9ZZG.png': { uri: '/images/2qohUS6C5LN8aNdtit9ZZG.png' },
'26UEK6mQLqR63jJYZ3ZVVu.webp': { uri: '/images/26UEK6mQLqR63jJYZ3ZVVu.webp' },
'fC8K43cB6h9jk48rpgri36.png': { uri: '/images/fC8K43cB6h9jk48rpgri36.png' },
'dF9VV9czmNVV7dnFys3hL6.png': { uri: '/images/dF9VV9czmNVV7dnFys3hL6.png' },
'5G3WMrVjYetGuaigMoLXS4.png': { uri: '/images/5G3WMrVjYetGuaigMoLXS4.png' },
'jB4xFgXG8eUJ1wyoDD8Afy.png': { uri: '/images/jB4xFgXG8eUJ1wyoDD8Afy.png' },
'r86eFAXJJ9NzWyMuDYEvjW.png': { uri: '/images/r86eFAXJJ9NzWyMuDYEvjW.png' },
'2at1kBjGYJ6Ydsb57BeVhc.png': { uri: '/images/2at1kBjGYJ6Ydsb57BeVhc.png' },
'h86CfiMndxrdCJh7QA3pkk.png': { uri: '/images/h86CfiMndxrdCJh7QA3pkk.png' },
'hoQGyvqT2YQ9FJ4w5XTyos.png': { uri: '/images/hoQGyvqT2YQ9FJ4w5XTyos.png' },
'r6XhfEyodDeHRdmQsqhCdm.png': { uri: '/images/r6XhfEyodDeHRdmQsqhCdm.png' },
'bQMbUvT9EejuoueBNqu9Ym.png': { uri: '/images/bQMbUvT9EejuoueBNqu9Ym.png' },
'93vQpA58qXPfiuFGqjgA86.png': { uri: '/images/93vQpA58qXPfiuFGqjgA86.png' },
'thkutb8PSHcxgUCbuHiiUZ.png': { uri: '/images/thkutb8PSHcxgUCbuHiiUZ.png' },
'a4B3Y4ET7Q3HKkewoeBfyA.png': { uri: '/images/a4B3Y4ET7Q3HKkewoeBfyA.png' },
'85YwshHzFnnwSzAt4Vv6c9.png': { uri: '/images/85YwshHzFnnwSzAt4Vv6c9.png' },
'ewAsW8kfQ2QSm3dQtYpyn4.png': { uri: '/images/ewAsW8kfQ2QSm3dQtYpyn4.png' },
'ci3SSamWdEoVWzttxFAC3a.png': { uri: '/images/ci3SSamWdEoVWzttxFAC3a.png' },
'5wQU3p4AcyuYjcfqW3hixm.png': { uri: '/images/5wQU3p4AcyuYjcfqW3hixm.png' },
'vsFfdvdVZCAYf65TqVEKpD.png': { uri: '/images/vsFfdvdVZCAYf65TqVEKpD.png' },
'ktmTfeqZEGc6BNbJ4Ffrzo.png': { uri: '/images/ktmTfeqZEGc6BNbJ4Ffrzo.png' },
'r2a94vj7K8cVXsaBpeFGVC.png': { uri: '/images/r2a94vj7K8cVXsaBpeFGVC.png' },
'4Lkc9FZ6TfX4gv3CC8efM2.png': { uri: '/images/4Lkc9FZ6TfX4gv3CC8efM2.png' },
'd5HHr4PfjdJuAmY14rFhE8.png': { uri: '/images/d5HHr4PfjdJuAmY14rFhE8.png' },
'1bJ9sMrBCgKkJsMx5tGLTq.png': { uri: '/images/1bJ9sMrBCgKkJsMx5tGLTq.png' },
'gTGHK4M2ak55nagtLd5QMd.png': { uri: '/images/gTGHK4M2ak55nagtLd5QMd.png' },
'gZp4CHhfdNyuSEK2T4RdWe.png': { uri: '/images/gZp4CHhfdNyuSEK2T4RdWe.png' },
'3jvwhhnZY2Ths6frm2mzgF.png': { uri: '/images/3jvwhhnZY2Ths6frm2mzgF.png' },
'nGVJgbYPxtPpUaU2393T37.png': { uri: '/images/nGVJgbYPxtPpUaU2393T37.png' },
'aMoaAkz5eaNc9DPJfAcPtj.png': { uri: '/images/aMoaAkz5eaNc9DPJfAcPtj.png' },
'ntYtZ8TuyH3zQBGSoKZr6m.png': { uri: '/images/ntYtZ8TuyH3zQBGSoKZr6m.png' },
'pjVVBN8XNaDs6r8wC2sguv.png': { uri: '/images/pjVVBN8XNaDs6r8wC2sguv.png' },
'bt5GVnsarDwf2QmTz35LsL.png': { uri: '/images/bt5GVnsarDwf2QmTz35LsL.png' },
'vvQfj5nzqSahjERai1YJ48.png': { uri: '/images/vvQfj5nzqSahjERai1YJ48.png' },
'mwKrc42UtYz9FGqd2ybZum.png': { uri: '/images/mwKrc42UtYz9FGqd2ybZum.png' },
'xuyiLQee8tz8xQLNGfp4Ld.png': { uri: '/images/xuyiLQee8tz8xQLNGfp4Ld.png' },
'oUbUt1xMLymt4WR752J8yB.png': { uri: '/images/oUbUt1xMLymt4WR752J8yB.png' },
'g3SavDygcnhbNHtxzjqR1z.png': { uri: '/images/g3SavDygcnhbNHtxzjqR1z.png' },
'3SX4Zmgr1jbWXVCY1xX6eB.png': { uri: '/images/3SX4Zmgr1jbWXVCY1xX6eB.png' },
'b1DcnBEja6wFichNnzGztL.png': { uri: '/images/b1DcnBEja6wFichNnzGztL.png' },
'1WTMC6V9Q8i9UhEWcAFi2e.png': { uri: '/images/1WTMC6V9Q8i9UhEWcAFi2e.png' },
'wLYm2a4Qes1kFftJ97Axpy.png': { uri: '/images/wLYm2a4Qes1kFftJ97Axpy.png' },
'oVkoEC9C9VTZU1LPXAJrRm.png': { uri: '/images/oVkoEC9C9VTZU1LPXAJrRm.png' },
'vWsR5dYhsoNC9h4B9FD5F8.png': { uri: '/images/vWsR5dYhsoNC9h4B9FD5F8.png' },
'uHMLnJoHCX2DhKW8Gp7dUw.png': { uri: '/images/uHMLnJoHCX2DhKW8Gp7dUw.png' },
'c64Z9DcMwFrZxukY5ZvEvP.png': { uri: '/images/c64Z9DcMwFrZxukY5ZvEvP.png' },
'5geVFaqrkJpxF5kwn3GGiN.png': { uri: '/images/5geVFaqrkJpxF5kwn3GGiN.png' },
'iHin1Sw355s8D9QN4b5Fck.png': { uri: '/images/iHin1Sw355s8D9QN4b5Fck.png' },
'aTnd8kFCmfL5GMXHoEtPt8.jpg': { uri: '/images/aTnd8kFCmfL5GMXHoEtPt8.jpg' },
'49F2Mrdtku1cLMPvc27fbC.png': { uri: '/images/49F2Mrdtku1cLMPvc27fbC.png' },
'cJb9W64nz1HDojtoTFgGcp.webp': { uri: '/images/cJb9W64nz1HDojtoTFgGcp.webp' },
'wTXfyG6XiJx3oD54NcMhYe.png': { uri: '/images/wTXfyG6XiJx3oD54NcMhYe.png' },
'i2FLe2SsFGYLTfCsPTqNqq.jpg': { uri: '/images/i2FLe2SsFGYLTfCsPTqNqq.jpg' },
'b5sLPdgjbUt1Kf9HuhQZMC.png': { uri: '/images/b5sLPdgjbUt1Kf9HuhQZMC.png' },
'kLHTGKvRzr1HdAs54UMRTb.jpg': { uri: '/images/kLHTGKvRzr1HdAs54UMRTb.jpg' },
'oEYGAHWLu7L9nx9jCYMpET.jpg': { uri: '/images/oEYGAHWLu7L9nx9jCYMpET.jpg' },
'4hRRuBVN3SEFHyfYkpuK3B.jpg': { uri: '/images/4hRRuBVN3SEFHyfYkpuK3B.jpg' },
'rsGbPfHi8kaFhMDiMtcymV.jpg': { uri: '/images/rsGbPfHi8kaFhMDiMtcymV.jpg' },
'w4CpBwdQzSq6192a22BQnC.png': { uri: '/images/w4CpBwdQzSq6192a22BQnC.png' },
'ciogqgYcqMZfm2sREKs6MU.jpg': { uri: '/images/ciogqgYcqMZfm2sREKs6MU.jpg' },
'19cTdGokY4xXfY8xpvEjZa.png': { uri: '/images/19cTdGokY4xXfY8xpvEjZa.png' },
'cX48WJ5re3Z1JD8DuRrySH.jpg': { uri: '/images/cX48WJ5re3Z1JD8DuRrySH.jpg' },
'rkWzMmkyFhZmwdfuxT2wsn.jpg': { uri: '/images/rkWzMmkyFhZmwdfuxT2wsn.jpg' },
'4kp2i36EwLUjdQzTSXCWuA.png': { uri: '/images/4kp2i36EwLUjdQzTSXCWuA.png' },
'j3bg8e9bTrDG737vVpwJqt.jpg': { uri: '/images/j3bg8e9bTrDG737vVpwJqt.jpg' }
}

export default imageStaticSourcesByFileName
